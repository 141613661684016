<script setup lang="ts">
import { openNewWindowAsync } from '~/utils/helper'
const props = withDefaults(defineProps<Props>(), {})

enum bannerTypes {
  TYPE1 = 1,
  TYPE2 = 2
}
interface Props {
  bannerUrlList?: array<T>
  width?: string | '100%'
  height?: string | '100%'
}
const router = useRouter()

function clickBannerEl(val: any) {
  if (val.isLink === 1 && val.linkTarget === 1) {
    openNewWindowAsync(({ openWin }) => openWin(val.targetUrl))
  }
  if (val.isLink === 1 && val.linkTarget === 3) {
    router.push(`/details/${getVideoType(val.videoTypeId) || 'movie'}/${val.targetUrl}`)
  }
}
</script>

<template>
  <Swiper
    h-full
    :style="{ width, height }"
    :modules="[SwiperAutoplay, SwiperEffectCreative]"
    :slides-per-view="1"
    :loop="true"
    :pagination="true"
    effect="creative"
    :autoplay="{
      delay: 3000,
      disableOnInteraction: false
    }"
    :creative-effect="{
      prev: {
        shadow: false,
        translate: ['-100%', 0, -1]
      },
      next: {
        translate: ['100%', 0, 0]
      }
    }"
    rounded-2
  >
    <!-- :placeholder="getBannerBgImg"  -->
    <SwiperSlide v-for="(slide, index) in bannerUrlList" :key="index" cursor-pointer>
      <ImgWrapper v-if="slide.pictureUrl" :src="slide.pictureUrl" placeholder="/banner-1-defbg.png" />
    </SwiperSlide>
  </Swiper>
</template>
